import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const HowToInstantWinCampaign1 = ({ location }) => {
  const title =
    "初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1"
          pagedesc="販促につながるTwitterインスタントウィンキャンペーンを開催するまでの手順を自社の実例と共に紹介します。実施に必要なチェックリスト付きで、すぐに使えるキャンペーン設計のコツが分かります。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-instant-win-campaign-1/how-to-instant-win-campaign-1.jpg"
          pagepath="/blog/how-to-instant-win-campaign-1"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は5分で読むことができます
            </div>
            <h1>
              初めてでもOK！インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.1
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
             {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2022年10月19日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="チェックリストにチェックをしている人"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-instant-win-campaign-1/how-to-instant-win-campaign-1.jpg"
                className="w-full"
              />
            </figure>
            <div>
              <p>
                認知度アップやECサイトへの集客に大きな効果が見込める、「Twitterインスタントウィンキャンペーン」。気にはなるけど、具体的にどうやって始めればいいか分からないとお困りのマーケ担当者も多いようです。
              </p>
              <p>
                この記事では、PARKLoTが開催したキャンペーンを例に、Twitterインスタントウィン開催のために必要な4ステップのうち、ステップ1
                目標設定、ステップ2 景品設定を解説します。
              </p>
              <p>
                文末のチェックリストを使えば、貴社でも「成果につながる」インスタントウィンキャンペーンが企画できるようになります。
              </p>
              <p>
                <br />
                この記事は次のような人にオススメです！
              </p>
              <ul className="blog__border-list">
                <li>Twitterインスタントウィンを初めて開催したい</li>
                <li>きちんと販促につながるTwitterキャンペーンがやりたい</li>
                <li>キャンペーンツールの導入を検討している</li>
              </ul>
            </div>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    Twitterインスタントウィンキャンペーンとは？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    実例紹介と概要
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    ステップ1：達成したい目標は？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    ステップ2：プレゼントする景品は？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id5" className="toc__link">
                    チェックリスト
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">Twitterインスタントウィンキャンペーンとは？</h2>
              <p>
                「インスタントウィン」とは、抽選結果がすぐに分かることを意味します。
              </p>
              <p>
                ユーザーとの距離が近いSNSとの相性が良く、特に、拡散性が高いTwitterでのプレゼント企画に多く採用されています。インスタントウィンは、応募の簡単さでユーザーに人気の「フォロー＆リツイートキャンペーン」と組み合わせて実施されることで、その効果が倍増します。フォロワー獲得と認知拡大が期待できるため、多くの企業アカウントが実施しているのが、Twitterインスタントウィンです。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/instant-win-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterインスタントウィンとは？リツイートキャンペーンとの違いは？
                  </a>
                </p>
              </div>
              <p>今回は、この最もメジャーなTwitterキャンペーンである、</p>
              <p>
                <strong>
                  インスタントウィン仕様のフォロー＆リツイートキャンペーン
                </strong>
              </p>
              <p>を開催するために準備した内容を解説します。</p>
            </div>
            <div>
              <h2 id="id2">実例紹介と概要</h2>
              <blockquote className="twitter-tweet">
                <p lang="ja" dir="ltr">
                  ／<br />
                  PARKLoT🎃
                  <a href="https://twitter.com/hashtag/%E3%83%8F%E3%83%AD%E3%82%A6%E3%82%A3%E3%83%B3%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%9A%E3%83%BC%E3%83%B3?src=hash&amp;ref_src=twsrc%5Etfw">
                    #ハロウィンキャンペーン
                  </a>{" "}
                  <br />
                  第2弾！
                  <br />＼
                  <a href="https://twitter.com/hashtag/%E3%83%95%E3%82%A9%E3%83%AD%E3%83%BCRT?src=hash&amp;ref_src=twsrc%5Etfw">
                    #フォローRT
                  </a>{" "}
                  でその場で当たる！
                  <a href="https://twitter.com/hashtag/Amazon%E3%82%AE%E3%83%95%E3%83%88%E5%88%B8?src=hash&amp;ref_src=twsrc%5Etfw">
                    #Amazonギフト券
                  </a>{" "}
                  を100名様にプレゼント💫
                  <br />
                  <br />
                  今すぐリツイートしてね！
                  <br />
                  <br />
                  【応募方法】
                  <br />
                  1️⃣{" "}
                  <a href="https://twitter.com/PARKLoT1?ref_src=twsrc%5Etfw">
                    @PARKLoT1
                  </a>{" "}
                  をフォロー
                  <br />
                  2️⃣この投稿をRT
                  <br />
                  📩当選者のみDM送付
                  <br />
                  〆切 10/24{" "}
                  <a href="https://t.co/CP6mnem3vE">
                    pic.twitter.com/CP6mnem3vE
                  </a>
                </p>
                &mdash;
                【公式】PARKLoT🎯インスタントウィンを最速で実施できるキャンペーンツール
                (@PARKLoT1){" "}
                <a href="https://twitter.com/PARKLoT1/status/1582131857793372160?ref_src=twsrc%5Etfw">
                  October 17, 2022
                </a>
              </blockquote>
              <p>
                例として、
                <a
                  href="https://twitter.com/PARKLoT1"
                  target="_blank"
                  rel="noreferrer"
                >
                  弊社PARKLoT公式アカウント
                </a>
                で開催したフォロー＆リツイートキャンペーンを使用します。
              </p>
              <ul className="blog__border-list">
                <p>【概要】</p>
                <li>期間：10月18日〜10月24日の7日間</li>
                <li>
                  キャンペーンタイプ：インスタントウィン（フォロー＆リツイート）
                </li>
                <li>
                  参加方法：1.
                  <a
                    href="https://twitter.com/PARKLoT1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoT公式アカウント
                  </a>
                  をフォロー
                  <br />
                  　　　　　2.キャンペーンツイートをリツイート
                </li>
                <li>結果通知：当選者にのみ即時DMでAmazonギフトコードを送付</li>
              </ul>
            </div>
            <div>
              <h2 id="id3">ステップ1：達成したい目標は？</h2>
              <p>まずは、キャンペーンの目的とKPIを明確に設定しました。</p>
              <p>
                キャンペーンの目的としては、フォロワー獲得/認知拡大/店舗集客/EC売上アップなどが考えられます。アカウントのフォロワー状況やビジネス内容によって変わってきますが、キャンペーン参加者にどういった訴求をしたいのか、クリアにしておく必要があります。
              </p>
              <p>
                KPIは、フォロワー数/リツイート数/販促商品の売上/サイト流入数/クーポン利用率など、数値で把握できる指標を設定しておきましょう。キャンペーンの開催結果を分析し、PDCAを回すために必要です。
              </p>
              <ul className="blog__border-list">
                <p>例）</p>
                <li>
                  目的：PARKLoT公式インスタントウィンに参加するユーザーを増やし、認知を促進したい
                </li>
                <li>KPI：リツイート数（目標3000リツイート）</li>
              </ul>
              <p>
                PARKLoTの例では、「その場で当たるインスタントウィンのワクワク」をより多くの方に実感してもらうことが目的です。その結果、インスタントウィンツール導入を検討する企業様が増えることが最終目的です。
              </p>
              <p>
                そのために、今回のキャンペーンのKPIはリツイート数としました。
              </p>
              <p>
                <br />
                また、この段階でTwitterのルールを必ず確認するようにしましょう。
              </p>
              <a
                href="https://help.twitter.com/ja/rules-and-policies/twitter-contest-rules"
                target="_blank"
                rel="noreferrer"
              >
                キャンペーンの実施についてのガイドライン | Twitterヘルプ
              </a>
            </div>
            <div>
              <h2 id="id4">ステップ2：プレゼントする景品は？</h2>
              <ul className="blog__border-list">
                <p>例）</p>
                <li>当選景品：Amazonギフト券100円分</li>
                <li>当選人数：100名</li>
                <li>当選確率：1/500</li>
                <li>
                  参加者の当選フィルター設定：デフォルトプロフィールユーザの当選を許可しない
                </li>
                <li>当選者の最低フォロワー数：50人</li>
              </ul>
              <h3>＜当選景品＞</h3>
              <p>
                その場で当たった参加者がすぐに使えてうれしい景品として、Amazonギフト券を設定しました。
              </p>
              <p>
                Amazonギフト券は、デジタルコード形式なので物理的な発送の手間も要らず、参加者と開催者の双方にメリットがある景品だといえます。QUOカードPayを設定し、オリジナルデザインの当選コードを送るパターンも増えています。
              </p>
              <p>
                （SNSキャンペーンツールPARKLoTには、「デジタルコードDM自動送付機能」が備わっています。Amazonギフト券やQUOカードPayにも対応していますので、今回はその機能を使って手軽にインスタントウィンを開催しました。サービスについて詳しくは
                ▶︎
                <a
                  href="/case-study/twitter/instant-win-digital-gift"
                  target="_blank"
                  rel="noreferrer"
                >
                  フォロー＆リツイート・ギフト券送付（インスタントウィン）
                </a>
                ）
              </p>
              <h3>
                <br />
                ＜当選人数＞
              </h3>
              <p>
                気になる当選人数ですが、いくら当たるかより「何人に当たるか」を優先させるのがおすすめです。「1万円分を1名にプレゼント」よりも「100円分を100名にプレゼント」の方が、多くの参加が見込めます。「100名当選なら自分にも当たるかも！」とユーザーの参加を後押しでき、拡散しやすくなります。
              </p>
              <h3>
                <br />
                ＜当選確率＞
              </h3>
              <p>
                1/500で当選する設定でスタートしました。PARKLoTでインスタントウィンを開催する場合、キャンペーンの途中でも当選確率の変更が可能です。
              </p>
              <h3>
                <br />
                ＜参加者の当選フィルター設定＞
              </h3>
              <p>
                不正応募などを防ぐ仕組みとして、Twitterプロフィール写真が初期のままのユーザーを除外したり、最低フォロワー数を指定することができます。
              </p>
              <p>
                <br />
                できるかぎりたくさんの方に応募してもらうため、上記の設定でPARKLoTのキャンペーン予約設定をしました。
              </p>
              <p>
                <br />
                以上、今回はインスタントウィン企画の概要、ステップ1：目標設定、ステップ2：景品設定について解説しました。
              </p>
              <p>
                次の記事では、ステップ3：応募方法と結果通知、ステップ4：キャンペーン投稿、開催後の振り返りについて紹介します。
              </p>
              <div className="blog__border-highlight">
                <p>次の記事</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/how-to-instant-win-campaign-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【インスタントウィン企画】Twitterキャンペーン実例で解説します・役立つチェックリスト付き＜その2＞
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id5">チェックリスト</h2>
              <p>
                インスタントウィン企画チェックリストをご用意しましたので、ぜひお使いください！
              </p>
              <img
                className="blog__article-image"
                placeholder="tracedSVG"
                alt="インスタントウィン企画チェックリスト"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-instant-win-campaign-1/how-to-instant-win-campaign-1-2.jpg"
              />
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/how-to-instant-win-campaign-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    インスタントウィンキャンペーンのやりかた【無料チェックリスト】Vol.2
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/risks-of-campaign-implementation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プレゼントキャンペーンを社内で実施するリスクとその回避方法
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/comparison-no-tool"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterキャンペーンで本当にツールは必要なのか？
                    お金をかけずに手動でできる方法は？
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/examples-of-utilization"
                    target="_blank"
                    rel="noreferrer"
                  >
                    あのブランドはどうやってファンを獲得した?PARKLoT活用事例4選を解説!
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default HowToInstantWinCampaign1
